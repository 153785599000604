import { ThemeOptions } from '@mui/material'
import { ptBR } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    highlight: Palette['primary']
    complement: Palette['primary']
  }
  interface PaletteOptions {
    highlight: PaletteOptions['primary']
    complement: PaletteOptions['primary']
  }
}

export const palette = {
  primary: {
    main: '#00398D',
    light: '#00398D',
    dark: '#001F4D',
    contrastText: '#15CB85',
  },
  secondary: {
    main: '#15CB85',
    light: '#10B073',
    dark: '#047B4E',
    contrastText: '#00398D',
  },
  highlight: { main: '#15CB85', light: '#10B073', dark: '#047B4E' },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#11181C',
    secondary: '#687076',
    disabled: '#889096',
  },
  complement: { main: '' },
  success: { main: '#15CB85', light: '#86E4C0', dark: '#30A46C' },
  warning: { main: '#FFB224', light: '#F3BA63' },
  error: { main: '#DC3D43', light: '#F3AEAF', dark: '#CD2B31' },
  info: { main: '#1856B4', light: '#4989E9', dark: '#00398D' },
}

export const typography: Partial<TypographyOptions> = {
  fontFamily: ['Open Sans', 'sans-serif'].join(','),
  htmlFontSize: 10,
  h1: {
    fontSize: '3.6rem',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '3rem',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
  },
  h6: {
    fontSize: '1.6rem',
    fontWeight: 900,
  },
  subtitle1: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
  },
  subtitle2: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    display: 'inline',
  },
  body1: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
  },
  body2: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  caption: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    display: 'block',
  },
  overline: {
    fontSize: '1rem',
    fontWeight: 'normal',
    display: 'block',
    textTransform: 'none',
  },
  button: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
}

export const clientTheme: ThemeOptions = {
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          fontFamily: 'Open Sans, sans-serif',
        },
        html: {
          fontSize: '62.5%' /* 62.5% of 16px = 10px */,
          scrollBehavior: 'smooth',
        },
        'html, body, #root': {
          height: '100%',
          minHeight: '-webkit-fill-available',
        },
        'input:-webkit-autofill': {
          transitionDelay: '9999999s',
        },
        '#notistack-snackbar': {
          color: '#fff !important',
          fontSize: `${typography.body1?.fontSize} !important`,
          fontWeight: `${typography.body1?.fontWeight} !important`,

          '& > svg': {
            marginRight: '16px !important',
          },
        },
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#C1C8CD',
          borderRadius: '8px',
        },

        a: {
          textDecoration: 'none',
          '&:visited': {
            color: 'inherit',
          },
        },

        '& .Mui-disabled': {
          color: `${palette.text.disabled}!important`,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
          maxHeight: '40px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          color: palette.background.paper,
          fontWeight: 'bold',
          fontSize: typography.body1?.fontSize,
          '& .MuiAlert-icon': {
            color: palette.background.paper,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: typography.body2?.fontSize,
          backgroundColor: '#11181C',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
          paddingBottom: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '0px',
          width: 'unset',
          '&:active': {
            '& .MuiSwitch-thumb': {},
            '& .MuiSwitch-switchBase.Mui-checked': {},
          },
          '& .MuiButtonBase': {
            padding: '0px',
          },

          '& .MuiSwitch-switchBase': {
            padding: '3px 4px',
            color: palette.background.paper,

            '&.Mui-checked': {
              padding: '3px 2px',
              color: palette.background.paper,
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: '13px',
            height: '13px',
          },
          '& .MuiSwitch-track': {
            borderRadius: '20px',
            height: '20px',
            width: '40px',
            backgroundColor: '#8B9094',
            opacity: '1 !important',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
          '&:active': {
            '& .MuiCheckbox-svg': {
              fill: palette.primary.main,
            },
          },
          '& .MuiButtonBase': {
            padding: '0px',
          },
        },
      },
    },
  },
}

export const GRAPH_COLORS = [
  '#30A46C',
  '#FFB381',
  '#FA934E',
  '#A8D9BD',
  '#3D454A',
  '#C1C8CD',
  '#0091FF',
  '#EB9091',
  '#15CB85',
  '#F76808',
  '#CEE7FE',
  '#CD2B31',
  '#00254D',
  '#BD4B00',
  '#DC3D43',
  '#96C7F2',
  '#00398D',
  '#FFA01C',
  '#FFE3A2',
  '#8A2BE2',
  '#5F9EA0',
  '#D2691E',
  '#FF7F50',
  '#6495ED',
]

export default createTheme(clientTheme, ptBR)
